// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .cmd-window {
        font-size: 10px;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .cmd-window {
        font-size:medium;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .cmd-window {
        font-size: medium;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .cmd-window {
        font-size: medium;
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .cmd-window {
        font-size: 10px;
    }
}

.text-white {
    color: white !important;
}

